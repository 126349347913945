<template>
  <div>
    <MyHeader />
    <main id="content"
          v-if="country && country.image">
      <div class="background-introduction align-items-end"
           :style="{ backgroundImage: 'url(' + country.image.path + ')' }">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-center">
              <h4 class="m-1">
                {{ country.name }}
              </h4>
              <p>Stay with all the comforts of home, verified.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row mb-4">
          <div class="col">
            <h4 class=" mt-5">
              Experiences in {{ country.name }}
            </h4>
          </div>
        </div>
        <div class="container mt-5 destinations recomendations">
          <ul class="mt-4 destinations-list">
            <li v-for="(_experience, index) in experiences"
                :key="index">
              <router-link :to="{ name: 'results', query: { destination: _experience.destination }}"
                           :style="{ backgroundImage: 'url(' + _experience.image.path + ')' }">
                <div class="destinations-info">
                  <h4>{{ _experience.title }}</h4>
                  <!-- <hr>
                  <p><small>{{ _experience.verified_stays }} + Verified Stays</small></p> -->
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- <PropertyList :config="{ limit: 8, sort_field: 'created_at', sort_order: 'DESC', destination: { type: 'country', id: country.id }, category_id: $route.query.category_id}" /> -->
        <hr class="mt-5">
      </div>
      <!-- <div class="container mt-5 destinations recomendations">
        <h4>Others <span>HOME <span class="black">BE</span> LIKE</span> destinations</h4>
        <ul class="mt-4 destinations-list">
          <li v-for="(_experience, index) in experiences"
              :key="index">
            <router-link :to="{ name: 'experience-detail', query: { destination_type: 'country', destination_id: _experience.id }}"
                        :style="{ backgroundImage: 'url(' + _experience.image.path + ')' }">
              <div class="destinations-info">
                <h4>{{ _experience.name }}</h4>
                <hr>
                <p><small>{{ _experience.verified_stays }} + Verified Stays</small></p>
              </div>
            </router-link>
          </li>
        </ul>
      </div> -->
    </main>
    <MyFooter />
  </div>
</template>

<script>
// import PropertyList from '@/components/PropertyList.vue';

export default {
  name: 'ExperienceDetail',
  data() {
    return {
      experiences: [],
      experience: {},
      country: {},
      destination: {},
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `Experiences in ${this.country && this.country.name ? this.country.name : ''}`,
      // all titles will be injected into this template
      // titleTemplate: '%s | Exclusive experience specialists',
      meta: [
        { name: 'description', content: `Discover great experiences in ${this.country && this.country.name ? this.country.name : ''}.` },
        // Schema.org markup for Google+
        { itemprop: 'name', content: `Experiences in ${this.country && this.country.name ? this.country.name : ''} | CapCana Rentals` },
        { itemprop: 'description', content: `Discover great experiences in ${this.country && this.country.name ? this.country.name : ''}.` },
        { itemprop: 'image', content: this.country && this.country.image ? this.country.image.path : '' },
        // Twitter Card data
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: `Experiences in ${this.country && this.country.name ? this.country.name : ''} | CapCana Rentals` },
        { name: 'twitter:description', content: `Discover great experiences in ${this.country && this.country.name ? this.country.name : ''}.` },
        // Twitter summary card with large image must be at least 280x150px
        { name: 'twitter:image:src', content: this.country && this.country.image ? this.country.image.path : '' },
        // Open Graph data
        { property: 'og:title', content: `Experiences in ${this.country && this.country.name ? this.country.name : ''} | CapCana Rentals` },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: this.$route.fullPath },
        { property: 'og:image', content: this.country && this.country.image ? this.country.image.path : '' },
        { property: 'og:description', content: `Discover great experiences in ${this.country && this.country.name ? this.country.name : ''}.` },
      ]
    };
  },
  components: {
    // PropertyList
  },
  created() {
    // this.destination = JSON.stringify({ label: this.$route.query.destination_label, type: this.$route.query.destination_type, id: this.$route.query.destination_id });
    // this.destination = JSON.stringify({
    //   label: this.$route.query.destination_label,
    //   type: 'state',
    //   id: this.$route.query.destination_id
    // });
    this.$axios.get(`/v1/experiences/?destination_type=${this.$route.query.destination_type}&destination_id=${this.$route.query.destination_id}`).then((response) => {
      const experiencesList = response.data.data;
      const _experiences = [];
      let destination = {};
      for (let i = 0; i < experiencesList.length; i += 1) {
        destination = JSON.stringify({
          label: `${experiencesList[i].state.name}, ${experiencesList[i].country.name}`,
          type: 'state',
          id: experiencesList[i].state_id
        });
        _experiences.push({
          ...experiencesList[i],
          destination
        });
      }
      this.experiences = _experiences;
    });
    this.$axios.get(`/v1/locations/countries/${this.$route.query.destination_id}`).then((countryResponse) => {
      this.country = countryResponse.data.data;
    });
  },
};
</script>
